<template>
    <header>
        <div class="pageWidth">
            <div class="outer-logo-wrapper"><div class="inner-logo-wrapper"><router-link to="/" title="Biomar GmbH"><div class="biomarLogo"></div></router-link></div></div>
            <nav aria-label="mainNav">
                <ul>
                    <li><router-link to="/company">Firmenprofil</router-link></li>
                    <li><router-link to="/services">Leistungen</router-link></li>
                    <li><router-link to="/contact">Kontakt</router-link></li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<style scoped lang="scss">
@use "@/assets/css/main";
header {
    z-index: 999;
    position: fixed;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;
    height: 5rem;
    background-color: main.$biomar-dark;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
}

.outer-logo-wrapper {
    margin-left: -1rem;
    background-color: main.$biomar-dark-hover;
    transform: skew(-25deg);
    transition: 0.3s all ease-out;
    width: 22rem;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.3);
    clip-path: inset(0px -15px 0px -15px);
}

.inner-logo-wrapper {
    width: 20rem;
    transform: skew(25deg);
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

@media screen and (max-width: calc(1600px + 4.4rem)) {
    .outer-logo-wrapper {
        width: 24rem;
        transform: skew(-25deg) translateX(-2rem);
        > * {
            transform: skew(25deg);
            transition: none;
        }
    }
}

.pageWidth {
    max-width: 1600px;
    height: 100%;
    width: 100%;
    display: flex;
}

.biomarLogo {
    width: 100%;
    height: 100%;
    background: url(@/assets/BIOMAR_light.svg) no-repeat;
    background-size: 100%;
}

nav {
    margin-left: auto;

    ul {
      list-style: none;
      height: 100%;
      margin: 0 16px 0 16px;
      li {
        width: 8rem;
        a {
            padding-top: 2rem;
            color: white;
        }
        :hover {
            background-color: main.$biomar-dark-hover;
        }
      }
    }
}
</style>
