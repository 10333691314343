<template>
    <footer>
        <nav aria-label="footerNav">
            <ul>
                <li><router-link to="/impressum">Impressum</router-link></li>
                <li><router-link to="/datenschutz">Datenschutz</router-link></li>
            </ul>
        </nav>
    </footer>
</template>

<style scoped lang="scss">
@use "@/assets/css/main";
    footer {
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        width: 100%;
        background-color: main.$biomar-dark;
        height: 3rem;
        min-height: 3rem;
        box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.3);

    }
    nav{
        margin-left: auto;
        margin-right: auto;
        ul {
            display: flex;
            justify-content: center;
            padding-left: 0;
            li {
                a {
                    padding-top: 1rem;
                    color: white;
                }
                a.router-link-active,
                a.router-link-exact-active{
                    background-color: main.$biomar-dark-hover;
                }
            }
        }
    }

</style>
