
import MenuIcon from 'icons/Menu.vue'
import { defineComponent } from 'vue'
export default defineComponent({
  data () {
    return {
      showNav: false,
      hideNavbar: false,
      lastScrollPosition: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('click', this.onClick)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('click', this.onClick)
  },
  components: {
    MenuIcon
  },
  methods: {
    setShowNav () {
      // Der wird hier falsch ausgel&ouml;st
      // console.log('1')
      this.showNav = !this.showNav
    },
    closeNav () {
      this.showNav = false
    },
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0 || Math.abs(currentScrollPosition - this.lastScrollPosition) < 80) {
        return
      }
      // console.log('2')
      this.hideNavbar = this.lastScrollPosition < currentScrollPosition
      this.showNav = this.showNav && !this.hideNavbar
      this.lastScrollPosition = currentScrollPosition
    },
    onClick (ev: MouseEvent) {
      const biomarHeader = document.getElementById('biomarMobileHeader')
      if (biomarHeader && !biomarHeader.contains(ev.target as HTMLElement)) {
        // console.log('3')
        this.showNav = false
      }
    }
  }
})
