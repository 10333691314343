
import BiomarHeader from '@/components/BiomarHeader.vue'
import BiomarHeaderMobile from '@/components/BiomarHeaderMobile.vue'
import BiomarFooter from '@/components/BiomarFooter.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      mobileView: window.innerWidth <= 990
    }
  },
  components: {
    BiomarHeader,
    BiomarHeaderMobile,
    BiomarFooter
  },
  created () {
    document.documentElement.setAttribute('lang', 'de')
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResize)
    })
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.mobileView = window.innerWidth <= 990
    }
  }
})
