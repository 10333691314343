
export default {
  beforeMount (el: Element) {
    el.classList.add('fade-in-pre-enter')
  },
  mounted (el: Element) {
    // Observer to detec when elements enter the viewport
    const animatedScrollObserver = new IntersectionObserver(
      (entries, animatedScrollObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            el.classList.add('fade-in-enter')
            el.classList.remove('fade-in-pre-enter')
            el.classList.remove('fade-up-pre-enter')
            animatedScrollObserver.unobserve(entry.target)
          }
        })
      }, {
        threshold: 0.1
      }
    )
    animatedScrollObserver.observe(el)

    // Apply translation for elements outside the viewport
    requestAnimationFrame(() => {
      el.classList.add('fade-up-pre-enter')
    })
  }
}
